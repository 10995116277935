.weekDays-selector input {
    display: none!important;
}

.weekDays-selector input[type=checkbox] + label {
    display: inline-block;
    border-radius: 6px;
    background: #dddddd;
    height: 40px;
    width: 30px;
    margin-right: 3px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}

.weekDays-selector input[type=checkbox]:checked + label {
    background: #0c1981;
    color: #ffffff;
}

.weekday-container .mat-form-field + .mat-form-field {
    margin-left: 8px;
}

.weekday-container .mat-form-field {
    width: 220px;
}

.weekday-container form {
    margin-bottom: 20px;
}

.weekday-container form > * {
    margin: 5px 0;
}

.weekday-container .mat-radio-button {
    margin: 0 12px;
}
