@import "tailwindcss/base";

/* components injects any component (reusable styles like cards and form elements, etc.) classes registered by plugins based in our config file. */
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {

    input[type="checkbox"]:checked{
        background-color: theme('colors.wbt-color') !important;
    }

    fieldset.likertScale .likertIndicator {
        border: thin solid theme('colors.wbt-color');
        background-color: white;
    }

    .likertResponse > input:checked + .likertIndicator {
        background-color: theme('colors.wbt-color') !important;
    }

    .likertLine {
        --tw-border-opacity: 1;
        border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
    }

    .css-1pahdxg-control{
        border-color: theme('colors.wbt-color') !important;
    }

    .css-1pahdxg-control:hover{
        border-color: theme('colors.wbt-color') !important;
    }

    .css-1okebmr-indicatorSeparator{
        width: 0px !important;
    }

    .Mui-focused {
        color: #0c1981 !important;
    }

    .MuiInputBase-input:focus{
        border-color: theme('colors.wbt-color') !important;
        --tw-ring-color: theme('colors.wbt-color') !important;
    }

    .MuiInput-input{
        @apply text-sm !important;
        @apply font-medium !important;
        @apply font-sans !important;
    }

    .MuiInputLabel-root{
        @apply text-sm !important;
        @apply font-medium !important;

    }

}
